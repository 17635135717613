@import '~leaflet/dist/leaflet.css';

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

* {
	font-family: Inter, sans-serif !important;
	box-sizing: border-box;
}

html,
body {
	font-family: Inter, sans-serif;
	font-size: 16px;
	line-height: 1.5;
	color: #333;
}

button {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Background color of the scrollbar */
::-webkit-scrollbar-track {
	background-color: #f1f1f1;
	border-radius: 0 12px 12px 0;
}

/* Color of the scrollbar handle */
::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 12px;
}

/* Hover styles for the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}
